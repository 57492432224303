import axios from 'axios'
import qs from 'qs'
import ls from '../../utils/ls.js'
import {EventBus} from './eventBus.js';
import {
  ajaxLog
} from '../../utils/utils.js'
import store from '@/store'
let ignoreUrl = [''] //可视化部分展示页面忽略session会话校验
export default {
  install(Vue, options) {
    // 1. 添加全局方法或属性
    // axios.defaults.baseURL = 'https://api.example.com'
    // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
    axios.defaults.timeout = 1000 * 600 //超时十分钟
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    var axio = axios.create({
      baseURL: options.baseURL
    })
    axio.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    axio.interceptors.request.use(function (config) {
      // 请求发送前处理 为每个请求添加token
      const sessionId = ls.get('sessionId')
      var ignore = false
      for (var i =0;i<ignoreUrl.length;i++){
        ignore =  !(config.url.indexOf(ignoreUrl[i]) === -1)
        if (ignore)
          break;
      }


      var reqDomain = config.headers['TenantDomain']

      // console.log(config.url + "[reqDomain:" + reqDomain + "]")

      if(reqDomain != null){
        //saasRequest已设置reqDomain
        config.headers['TenantDomain'] = reqDomain;
                
        if(config.url.indexOf("?")<0){
          config.url += "?@saasRequest=true" //saasRequest已设置自定义的domain
        } else//已有？          
          config.url += '&@saasRequest=true'  //saasRequest已设置自定义的domain
      } else {
        //正常的url请求
        if(ls.get("tenantDomain")){
          config.headers['TenantDomain'] = ls.get("tenantDomain");   
        } else {
          config.headers['TenantDomain'] = window.location.hostname
        }
      }            
    
      if (sessionId && !ignore) {
        if (!config.headers.common.hasOwnProperty('Authorization')) {
          config.headers.common['Authorization'] = sessionId
        }
      }

      //请求增加域名的header,已在全局的xmlrequest 处理 这边删除
      // const hostname = document.location.hostname
      // config.headers.common['TenantDomain'] = hostname

      //  请求参数处理
      if (
        config.method.toLocaleUpperCase() === 'POST' ||
        config.method.toLocaleUpperCase() === 'PUT' ||
        config.method.toLocaleUpperCase() === 'DELETE'
      ) {
        if(config.headers['Content-Type']=='multipart/form-data' || config.headers['Content-Type']=='application/json;charset=utf-8'){
          config.data =  config.data
        }else{
          config.data = qs.stringify(config.data,{ indices: false }) // 格式化数据
        }
        
      }
      return config
    }, function (error) {
      // 请求错误处理
      return Promise.reject(error)
    })

    axio.interceptors.response.use(function (response) {
      // 返回成功处理
      //记录日志
      const s= response.data
      if (50001===s.code) {
        // 通过 $emit 事件通知 App.vue 显示验证码弹窗
        EventBus.$emit('showCaptcha');
        return;
      }
      ajaxLog(store.state.user, response.config.url, response.config.data)
      return response;
    }, function (error) {
      console.log(error)
      
      // 返回出错 处理
      // 全局处理返回失败，处理完不用reject error
      if (error && error.response.status === 401 ) {//&& store.state.user.authenticated
          store.dispatch('user/inValidAuthenticate');
          //方案1 session失效提示
          // Vue.prototype.$alert('会话已过期，请重新登录', '提示', {
          //   confirmButtonText: '确定',
          //   callback: action => {
          //     store.dispatch('user/logout').then(() => {
          //       window.location.reload()
          //       //store._vm.$router.replace('/login')
          //     });
          //   }
          // });
          // return Promise.reject(error)

          //方案2 session失效 直接退出到登录界面
          store.dispatch('user/logout').then(() => {
            window.location.href = "/login.html";
          });
          
      }else if (error.response && error.response.status === 504) {
          error.response.data =  {
            data :{
              msg:'请求超时，请稍后查看执行结果',
              success: false
          }
        }
      } else {
         if(typeof(error.response.data) === 'string'){
          error.response.data =  {
              data :{
                msg:'服务端返回有误',
                success: false
            }
          }
          
         }else{
          error.response.data = Object.assign(error.response.data, {
            success: false
          })
         }
        
        return error.response
      }

      // return Promise.reject(error)

    })
    Vue.http = axio; // 全局http 方法  Vue.http.get...
    Vue.prototype.$http = axio; // 实例方法 this.$http.get...
  }
}